import Image from "next/image";

import Link from "next/link";
import { UserAuthForm } from "components/layout/user-auth-form";

export default function Home() {
	return (
		<>
			{/* 1. Outer container: Full screen, centers the main content box */}
			<div className="flex min-h-screen items-center justify-center bg-background p-4">
				{/*
	  2. Centered Content Box: Fixed dimensions, uses flex for internal columns.
		 - `w-full max-w-[600px]`: Sets width, allows shrinking on small screens. Use `w-[600px]` for fixed.
		 - `h-[800px]`: Sets fixed height.
		 - `flex`: Makes this box a flex container for the two columns inside.
		 - `border rounded-lg shadow-md`: Visual styling.
		 - `overflow-hidden`: Important to clip the image within rounded corners if applicable.
	*/}
				<div className="w-full max-w-[800px] h-[800px] flex border rounded-lg shadow-md overflow-hidden bg-card">
					{/* 3. Left Column (Image) */}
					{/*
		 - `w-1/2`: Takes up half the width of the flex container.
		 - `h-full`: Takes up the full height of the flex container.
		 - `relative`: Needed for the Next.js Image with fill={true}.
		 - `hidden md:block`: Hides the image column on small screens (optional, like original).
							  Remove this if you always want to show the image column.
	  */}
					<div className="w-1/2 h-full relative hidden md:block">
						{" "}
						{/* Adjust or remove hidden/md:block as needed */}
						<Image
							// Replace with your actual image source
							src="/cityscape.png" // Or e.g., https://images.unsplash.com/photo-15...
							alt="Cityscape"
							fill // Makes the image fill the container
							style={{ objectFit: "cover" }} // Ensures the image covers the area without distortion
							priority // Load image sooner if it's above the fold
						/>
						{/* Optional: Overlay like in the original example */}
						<div className="absolute inset-0 bg-zinc-900/60" /> {/* Example overlay */}
						{/* Optional: Quote like in the original example */}
						<div className="relative z-10 flex h-full flex-col justify-between p-10 text-white">
							<blockquote className="space-y-2">
								<p className="text-2xl uppercase">
									<b>Elite</b> opportunities for <b>elite</b> lawyers.
								</p>
								<p className="text-md text-slate-300">The simple, no-nonsense legal recruitment platform for lateral moves.</p>
							</blockquote>
							<div className="flex items-center text-lg font-medium">LatList, LLC.</div>
						</div>
					</div>

					{/* 4. Right Column (Authentication Form) */}
					{/*
		 - `w-full md:w-1/2`: Takes full width on small screens (if left is hidden), half width on medium+.
		 - `h-full`: Takes full height.
		 - `flex flex-col items-center justify-center`: Centers the form content vertically and horizontally within this column.
		 - `p-8`: Padding inside the form column.
	  */}
					<div className="w-full md:w-1/2 h-full flex flex-col items-center justify-center p-6 md:p-8">
						{/* Adjusted padding */}
						{/* Form Wrapper - adjusted width constraints */}
						<div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px] max-w-full">
							<p className="px-4 sm:px-8 text-center text-sm text-muted-foreground">Log in with any of the providers below.</p>
							<UserAuthForm />
							<p className="px-4 sm:px-8 text-center text-sm text-muted-foreground">
								{" "}
								{/* Adjusted padding */}
								By clicking continue, you agree to our{" "}
								<Link href="/terms" className="underline underline-offset-4 hover:text-primary">
									Terms of Service
								</Link>{" "}
								and{" "}
								<Link href="/privacy" className="underline underline-offset-4 hover:text-primary">
									Privacy Policy
								</Link>
								.
							</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

Home.noauth = false;
Home.noheader = true;
