"use client";

import * as React from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GoogleIcon from "@mui/icons-material/Google";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import { signIn } from "next-auth/react";
import { Button } from "@/components/ui/button";

export function UserAuthForm({ className, ...props }) {
	const [isLoading, setIsLoading] = React.useState(false);
	const [activeProvider, setActiveProvider] = React.useState(null);

	const handleOAuthSignIn = async (provider) =>
		signIn(provider, {
			callbackUrl: "/login",
		});

	return (
		<div className={`grid gap-6 ${className}`} {...props}>
			<div className="grid gap-2">
				<Button
					className="bg-slate-900/90 text-slate-200 cursor-pointer"
					disabled={isLoading && activeProvider}
					onClick={() => handleOAuthSignIn("google")}
				>
					{isLoading && activeProvider === "google" ? (
						<HourglassBottomIcon className="mr-2 h-4 w-4 animate-spin" />
					) : (
						<GoogleIcon className="mr-2 h-4 w-4" />
					)}{" "}
					Google
				</Button>

				<Button
					className="bg-slate-900/90 text-slate-200 cursor-pointer"
					disabled={isLoading && activeProvider}
					onClick={() => handleOAuthSignIn("linkedin")}
				>
					{isLoading && activeProvider === "linkedin" ? (
						<HourglassBottomIcon className="mr-2 h-4 w-4 animate-spin" />
					) : (
						<LinkedInIcon className="mr-2 h-4 w-4" />
					)}{" "}
					LinkedIn
				</Button>
			</div>
		</div>
	);
}
